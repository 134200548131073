import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import TransPlainText from '@/components/common/TransPlainText';
const CategoryPage = lazy(() => import('@/pages/store_home/category/CategoryPage'));
const CategoryIntroPage = lazy(() => import('@/pages/store_home/category/CategoryIntroPage'));
const CollectionEditPage = lazy(() => import('@/pages/store_home/collection/CollectionEditPage'));
const CollectionManagementPage = lazy(() => import('@/pages/store_home/collection/CollectionManagementPage'));
const CollectionHomeDisplayManagementPage = lazy(
  () => import('@/pages/store_home/collection/CollectionHomeDisplayManagementPage'),
);
const MainBannerMainPage = lazy(() => import('@/pages/store_home/main_banner/MainPage'));
const StyleContentPage = lazy(() => import('@/pages/store_home/style-content/StyleContentPage'));

export const STORE_HOME_ROUTES: RoutesType[] = [
  {
    current_menu: 'store_home_category',
    path: '/store_home/category',
    params: '',
    component: <CategoryPage />,
  },
  {
    current_menu: 'store_home_category',
    path: '/store_home/category/intro',
    params: '',
    component: <CategoryIntroPage />,
  },
  {
    current_menu: 'store_home_main_banner',
    path: '/store_home/main_banner/list',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <MainBannerMainPage />,
    res: SellerResource.STORE_DISPLAY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'store_home_display',
    path: '/store_home/display',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title={<TransPlainText ns='store_home' i18nKey='컬렉션 전시 관리' />} />
        </PageHeader>
        <CollectionHomeDisplayManagementPage />
      </>
    ),
  },
  {
    current_menu: 'store_home_collection',
    path: '/store_home/collection/list',
    params: '',
    component: <CollectionManagementPage />,
  },
  {
    current_menu: 'collection_edit',
    path: '/store_home/collection/new',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title={<TransPlainText ns='store_home' i18nKey='컬렉션 등록' />} />
        </PageHeader>
        <CollectionEditPage />
      </>
    ),
  },
  {
    current_menu: 'collection_edit',
    path: '/store_home/collection/edit',
    params: '/:id',
    component: (
      <>
        <PageHeader>
          <SectionTitle title={<TransPlainText ns='store_home' i18nKey='컬렉션 수정' />} />
        </PageHeader>
        <CollectionEditPage />
      </>
    ),
  },
  {
    current_menu: 'store_home_style_content',
    path: '/store_home/style_content',
    params: '',
    is_support_mobile: true,
    is_support_app: true,
    component: <StyleContentPage />,
  },
];
