import { Alert, IconRocket } from '@croquiscom/pds';
import { t } from 'i18next';
import React from 'react';
import { checkRes, isAllowedMenuByCountry, isAllowedMenuBySiteId } from '../../utils/menu_list';
import { MenuEntry } from '../types';
import { CreateMenuTreeOptions } from './types';

import { SiteId } from '@/constants/site';
import { ShopType } from '@/types/info';
import { CountryCode, SellerResource } from 'api';
import { SHOP_KIDS_CATEGORY_ID } from 'rui/constants';

export function createAdMenuTree({ has_ad2, info, has_ad_campaign }: CreateMenuTreeOptions): MenuEntry[] {
  const is_zpay = info.type === ShopType.ZPAY;
  const is_kids_category = info.trait.category === SHOP_KIDS_CATEGORY_ID;
  const kidsCategoryHandler = () => {
    Alert({
      title: t<string>('main_frame::알림'),
      text: t<string>('main_frame::키즈 스토어는 광고를 집행할 수 없습니다.'),
    });
  };

  return [
    {
      id: 'ad',
      label: t('main_frame::광고 관리'),
      icon: <IconRocket size={16} color='currentColor' />,
      is_allowed:
        !info.is_parent && isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.패션_바이_카카오, SiteId.마이스토어]),
      items: [
        {
          id: 'zigzag',
          label: t('main_frame::지그재그 광고'),
          href: !is_kids_category ? '/ad/3.0/reports' : null,
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: is_kids_category ? kidsCategoryHandler : null,
        },
        {
          id: 'ad_billings',
          label: t('main_frame::광고비 관리'),
          href: '/wallet',
          is_allowed:
            has_ad_campaign &&
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: null,
        },
        {
          id: 'ad_notifications',
          label: t('main_frame::광고 알림 관리'),
          href: '/ad_display/settings/notification',
          is_allowed:
            has_ad_campaign &&
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: null,
        },
        {
          id: 'promotion_apply',
          label: t('main_frame::고객 미션형 리워드 광고'),
          href: '/promotion/deductions',
          is_allowed:
            is_zpay &&
            checkRes([], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
            isAllowedMenuByCountry(info, [CountryCode.KR]),
        },
        {
          id: 'fbk_ads',
          label: t('main_frame::[패션바이카카오] 핫 광고'),
          href: '/advertisement/fbk_ads',
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.패션_바이_카카오, SiteId.마이스토어]),
          onClick: null,
        },
        {
          // TODO: 파센 광고 개선 후 제거 예정
          id: 'zigzag_ads',
          label: t('main_frame::[지그재그] 파워업 Ai 광고(종료)'),
          href: !is_kids_category ? '/ad_display/management_two?tab=manage' : null,
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            has_ad2 &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그, SiteId.마이스토어]),
          onClick: is_kids_category ? kidsCategoryHandler : null,
        },
        {
          id: 'affiliate',
          label: t('main_frame::공유리워드 부스트 관리'),
          href: !is_kids_category ? '/affiliate' : null,
          is_new: true,
          is_allowed:
            checkRes([SellerResource.AD_DISPLAY_CONTENT_MANAGEMENT], info.allowed_resource_list) &&
            isAllowedMenuBySiteId(info, [SiteId.지그재그]) &&
            !is_kids_category,
          onClick: is_kids_category ? kidsCategoryHandler : null,
        },
      ],
    },
  ];
}
